<template>
    <div>
        <div class="container">
            <div class="card">
                <div class="card-header"><h1>We're sorry, <a href="#/not_found">Page</a> is nowhere to be found!</h1></div>
                <div class="card-body">
                    <h2>
                        <a id="four-o-four-contact-link">Contact the Developer</a>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>